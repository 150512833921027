import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Button, Table, Space, notification, Modal } from "antd";
import { deleteUser, getUsers, updateUser } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import "./User.css";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
function User({ navStore, authStore }) {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);


  const { confirm } = Modal;

  const showConfirmDeleteSelected = (user) => {
    confirm({
      title: `Are you sure you want to delete ${user.email}?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        await deleteUser(user.key);
        notification.open({
          message: "User deleted",
        });
        fetchData();
      },
      onCancel() { },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (org) => {
        return org?.name;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Space>
          {<button className="link" style={{ marginRight: "20px" }} onClick={() => navigate(`/user/edit/${record.key}`)}><EditOutlined /></button>}
          {authStore.userRole === "admin" && <button className="link" style={{ marginRight: "20px" }} onClick={() => showConfirmDeleteSelected(record)}><DeleteOutlined /></button>}
          {record.status === "INVITED" && <button onClick={() => resendInvitation(record)}>Resend</button>}
        </Space>
      ),
    },
  ];
  const fetchData = async () => {
    setLoading(true);
    getUsers().then((res) => {
      const users = res.map((x) => {
        const key = x.id;
        const name = `${x.firstname} ${x.lastname}`;
        const email = x.email;
        const role = x.role;
        const status = x.status;
        const organization = x.organization;
        return {
          key,
          email,
          name,
          role,
          status,
          organization,
        };
      });
      setUsers(users);
      setLoading(false);
    });
  }

  useEffect(() => {
    navStore.selectedKey = "3";
  }, [navStore]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resendInvitation = async (record) => {
    await updateUser(record.email, record.firstname, record.lastname, record.role, true);
    notification.open({
      message: "Invitation resent",
      description: "Invitation resent Successfully",
    });
  };

  return (
    <div className="User m-2">
      <div className="seperator">
        {!loading && ["admin", "org_admin"].indexOf(authStore.userRole) >= 0 && (
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => navigate("/user/add")}
          >
            Invite User
          </Button>
        )}
      </div>
      <h1>Users</h1>
      {loading && <Spin indicator={antIcon} />}
      {!loading && <Table dataSource={users} columns={columns} />}
    </div>
  );
}

export default observer(User);
