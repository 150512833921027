import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {onGoogleLogin} from "../project/GSC";
import {
  signup,
  authenticateUser,
  verifyAccount,
  resendConfirmation,
} from "./cognito";
import { useNavigate } from "react-router-dom";

import "./Signup.css";


const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function Signup({ authStore, navStore }) {
  const [resending, setResending] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");

  const [newPassword, setNewPassword] = useState(true);
  const [newPassword2, setNewPassword2] = useState(true);
  const [user, setUser] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  const navigate = useNavigate();

  navStore.updateSelectedKey("12");

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    try {
      if (newPassword !== newPassword2) {
        setError("Password mismatch");
        setSubmitting(false);
      } else {
        try {
          const result = await signup(
            email,
            newPassword,
            email,
          );
          setUser(result.user);
        } catch (e) {
          setError(e.message);
          setSubmitting(false);
        }
      }
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setError(e.message);
    }
  };

  const autoLogin = async () => {
    await authenticateUser(email, newPassword);
  };

  const resendCode = async () => {
    setResending(true);
    try {
      await resendConfirmation(email);
    } finally {
      setResending(false);
    }
  };

  const verify = async () => {
    setSubmitting(true);
    setError("");
    try {
      await verifyAccount(user.username, verificationCode);
      await autoLogin();
      navigate("/");
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="Signup">
      <div className="Signup__wrapper">
        <h1 className="Signup__header">Create a new account</h1>
        <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>
          <Button className="google-btn" onClick={onGoogleLogin}>
            <img
              class="google-icon"
              src="/Google__G__logo.svg"
              width="20px"
              alt="Google signup"
              style={{ marginRight: "10px" }}
            />
            Sign up with Google
          </Button>
          <div style={{"marginTop": "30px"}}>or</div>
        </div>

        <div className="Signup__container">
          {user == null && (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                className="Signup__item"
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter an email" }]}
              >
                <Input onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="New Password"
                name="newPassword"
                rules={[
                  { required: true, message: "Please enter a new password!" },
                ]}
              >
                <Input.Password
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className="Signup__item"
                label="Re-enter Password"
                name="newPassword1"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter your password!",
                  },
                ]}
              >
                <Input.Password
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> Signup</span>}
                </Button>
              </Form.Item>
            </Form>
          )}
          {user != null && (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={verify}
            >
              <p>
                We've sent a code to your email. Please enter the verification
                code.
              </p>
              <Form.Item
                className="Signup__item"
                label="Verification code"
                name="verificationCode"
                rules={[
                  { required: true, message: "Please verification code" },
                ]}
              >
                <Input onChange={(e) => setVerificationCode(e.target.value)} />
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> Verify</span>}
                </Button>
              </Form.Item>
              <div className="Signup__resend_code">
              <button className="link"onClick={() => resendCode()}>
                  {resending && <Spin indicator={<LoadingOutlined style={{ fontSize: 24}} spin />} />}
                  {!resending && <span> Resend code</span>}
                </button>
              </div>
              <div style={{clear:"both"}}></div>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Signup);
