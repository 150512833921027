import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { authenticateUser, completeChangePassword } from "./cognito";
import {onGoogleLogin} from "../project/GSC";

import "./Login.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

function Login({ authStore, navStore }) {
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState("");
  const [newPasswordFlow, setNewPasswordFlow] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const [newPassword, setNewPassword] = useState(true);
  const [newPassword2, setNewPassword2] = useState(true);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  navStore.updateSelectedKey("11");

  const onFinish = async () => {
    setError("");
    setLoggingIn(true);
    try {
      if (newPasswordFlow) {
        if (newPassword !== newPassword2) {
          setError("Password mismatch");
          setLoggingIn(false);
        } else {
          try {
            const user = await completeChangePassword(cognitoUser, newPassword);
            authStore.setSession(user);
            navigate("/");
          } catch (e) {
            setError(e.message);
            setLoggingIn(false);
          }
        }
      } else {
        const user = await authenticateUser(userName, password);
        authStore.setSession(user);
        navigate("/");
      }
      setLoggingIn(false);
    } catch (e) {
      if (e.type && e.type === "NEW_PASSWORD_REQUIRED") {
        setNewPasswordFlow(true);
        setCognitoUser(e.cognitoUser);
        setLoggingIn(false);
      } else {
        setLoggingIn(false);
        setError(e.message);
      }
    }

    // login().then(() => {
    //   navigate("/");
    // });
  };

  return (
    <div className="Login">
      <div className="Login__wrapper">
        <h1 className="Login__header">Welcome back!</h1>
        <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>
          <Button className="google-btn" onClick={onGoogleLogin}>
            <img
              class="google-icon"
              src="/Google__G__logo.svg"
              alt="Google login"
              width="20px"
              style={{ marginRight: "10px" }}
            />
            Sign in with Google
          </Button>
          <div style={{"marginTop": "30px"}}>or</div>
        </div>
        <div className="Login__container">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            {!newPasswordFlow && (
              <React.Fragment>
                <Form.Item
                  className="Login__item"
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please enter your username!" },
                  ]}
                >
                  <Input onChange={(e) => setUserName(e.target.value)} />
                </Form.Item>

                <Form.Item
                  className="Login__item"
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please enter your password!" },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </React.Fragment>
            )}

            {newPasswordFlow && (
              <React.Fragment>
                <h2>New password required</h2>
                <div>{userName}</div>

                <Form.Item
                  className="Login__item"
                  label="New Password"
                  name="newPassword"
                  rules={[
                    { required: true, message: "Please enter a new password!" },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="Login__item"
                  label="Re-enter Password"
                  name="newPassword1"
                  rules={[
                    {
                      required: true,
                      message: "Please re-enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword2(e.target.value)}
                  />
                </Form.Item>
              </React.Fragment>
            )}
            {error && <div className="error">{error}</div>}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {loggingIn && <Spin indicator={antIcon} />}
                {!loggingIn && (
                  <span> {newPasswordFlow ? "Set Password" : "Login"}</span>
                )}
              </Button>
            </Form.Item>

            {!newPasswordFlow && (
              <div className="Login__forgot_password">
                <button className="link" onClick={() => navigate("/forgot")}>
                  Forgot password?
                </button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(Login);
