import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { getUploadURLs, importProjectUrls } from "../../utils/api";
import { Button, Spin, notification, message, Upload } from "antd";

import "./CsvImport.css";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
function CsvImport({ projectId, closeModal }) {
  const [fileUrl, setFileUrl] = useState();
  const [fileKey, setFileKey] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const beforeUpload = (file) => {
    const isText = file.type === "text/plain" || file.type === "text/csv";
    if (!isText) {
      message.error("Please ensure the selected file is a csv file.");
    }
    return isText;
  };

  const clear = async () => {
    setSubmitEnabled(false);
    setFileKey("");
    const urls = await getUploadURLs(projectId);
    const fileURL = urls["file"];
    setFileUrl(fileURL);
  };

  const submitClicked = async () => {
    setSubmitting(true);
    try {
      console.log(fileUrl);
      await importProjectUrls(projectId, fileUrl.fields.key);
      notification.success({ message: "Urls imported successfully" });
      clear();
      closeModal();

    } catch (error) {
      const message = error.response.data.message;
      notification.error(message);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    async function generate() {
      const urls = await getUploadURLs(projectId);
      const fileURL = urls["file"];
      setFileUrl(fileURL);
    }
    generate();
  }, [projectId]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info, fileObj, setKey) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        const key = fileObj.fields.key;
        
        setKey(key);
      });
    }

    if (info.file.status === "removed") {
        setKey("");
    }
  };

  useEffect(() => {
    const submitEnabled = fileKey !== "";
    setSubmitEnabled(submitEnabled);
  }, [fileKey]);

  return (
    <div className="Settings m-2">
      <p>Please select a csv file to import.</p>
      
      <Upload
        data={fileUrl?.fields}
        showUploadList={true}
        action={fileUrl?.url}
        beforeUpload={beforeUpload}
        multiple={false}
        maxCount={1}
        onChange={(info) => handleChange(info, fileUrl, setFileKey)}
      >
         <Button icon={<UploadOutlined />}>
                Select csv file
              </Button>
      </Upload>
      <Button
        type="primary"
        block
        size="large"
        style={{ marginTop: "20px" }}
        disabled={!submitEnabled}
        onClick={() => submitClicked()}
      >
        {submitting ? (
          <Spin indicator={antIcon}></Spin>
        ) : (
          <span>Import URLs</span>
        )}
      </Button>
    </div>
  );
}

export default observer(CsvImport);
