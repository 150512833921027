import React, { useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import {saveOauthCode} from "../../utils/api";

export const redirectUri =
      process.env.REACT_APP_ENV === "development"
        ? "http://localhost:3000/callback"
        : "https://app.seohero.ai/callback";

export const loginRedirectUri = process.env.REACT_APP_ENV === "development"
? "http://localhost:3000/login-callback"
: "https://app.seohero.ai/login-callback";

export const cognitoClientId = process.env.REACT_APP_ENV === "development"
? "3mja94vsvd6mpl3iqc52ak1s38"
: "60qjjfdg3v10h0c0v0m9v0crv2";


export const authUrl = process.env.REACT_APP_ENV === "development" ? 
       "https://seo-hero-dev.auth.us-east-1.amazoncognito.com" : 
       "https://login.seohero.ai"


export const onGoogleLogin = () => {
  const URL = `${authUrl}/authorize?identity_provider=Google&redirect_uri=${loginRedirectUri}&response_type=CODE&client_id=${cognitoClientId}&scope=openid%20email%20profile`;
  window.location.replace(URL);
};

export const redirectToGoogleAuth = async (id) => {
  // Redirect the user to the Google OAuth 2.0 endpoint to grant access.
  const clientId =
    process.env.REACT_APP_ENV === "development"
      ? "610915865131-r4dp80smcm1qvsgee227p0es59lou21o.apps.googleusercontent.com"
      : "306733982512-ec425eoghtnsdqa2veh8l6cqjpcotb96.apps.googleusercontent.com";
  
  const scopes = ["https://www.googleapis.com/auth/webmasters.readonly", "openid"];
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&client_id=${encodeURIComponent(
    clientId
  )}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(scopes.join(" "))}&response_type=code&prompt=consent`;

  localStorage.setItem("projectId", id);
  window.location.href = authUrl;
};

function GoogleSearchConsoleAuth({ id, styleObj }) {
  return (
    <>
      <Button
        style={styleObj}
        className="ProjectDetail__header_button"
        type="outline"
        onClick={() => redirectToGoogleAuth(id)}
      >
        Connect To GSC
      </Button>
    </>
  );
}

export function GSC({ authStore, navStore }) {
  const navigate = useNavigate();
  
  const saveOauth = async (code) => {
    if(localStorage.hasOwnProperty("projectId")) {
      let projectId = localStorage.getItem("projectId");
      localStorage.removeItem("projectId");
      
      const resp = await saveOauthCode(code, redirectUri, projectId, "google");
      localStorage.setItem("googleAccessToken", resp["googleAccessToken"]);
      projectId = resp.project_id;
      navigate(`/project/${projectId}`);
    }
  };
  
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get("code");
    if (code) {
      saveOauth(code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}

export default GoogleSearchConsoleAuth;
