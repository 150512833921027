import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { URL } from "../../utils/api";
import { Button, Form, Select } from "antd";
import { getProjects } from "../../utils/api";

import "./ProjectSelection.css";
const { Option } = Select;

export function Shopify() {
  const [searchParams] = useSearchParams();
  const [projectSelection, setProjectSelection] = useState(null);
  const [projects, setProjects] = useState([]);
  const [formSelectedProject, setFormSelectedProject] = useState([]);

  const shop = searchParams.get("shop");

  const fetchData = useCallback(async () => {
    const projects = await getProjects();
    setProjects(projects);
  }, []);

  const onProjectChange = (newProj) => {
    setFormSelectedProject(newProj);
  };

  const next = async () => {
    if (formSelectedProject) {
      setProjectSelection(formSelectedProject);
        // await setupShopify(projectId, shop);
      document.location = `${URL}/project/${formSelectedProject}/shopify-auth?shop=` + shop+"&time="+new Date().getTime();
    }
  };

  useEffect(() => {
    const run = async () => {
      const projectId = localStorage.getItem("project");
      setProjectSelection(projectId);
      if (!projectId) {
        fetchData();
      } else {
        // await setupShopify(projectId, shop);
        localStorage.removeItem("project");
        document.location = `${URL}/project/${projectId}/shopify-auth?shop=` + shop +"&time="+new Date().getTime();
      }
    };
    run();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {projectSelection === null && (
        <div>
          <div className="ProjectSelection">
            <div className="ProjectSelection__wrapper">
              <div className="ProjectSelection__container">
                <Button
                  className="ProjectPermission__container_back"
                  type="text"
                ></Button>
                <h2>Select a project to integrate Shopify to</h2>
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  layout="vertical"
                  onFinish={next}
                >
                  <Form.Item
                    className="ProjectSelection__item"
                    label="Project"
                    name="name"
                    rules={[
                      { required: true, message: "Please select a project" },
                    ]}
                  >
                    <Select
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      onChange={onProjectChange}
                    >
                      {projects.map((x) => (
                        <Option key={x.id} value={x.id}>{`${x.name}`}</Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                      Next
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Shopify;
