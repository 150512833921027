import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { Spin, notification } from "antd";
import { LoadingOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import { inviteUser, getUserById, updateUser } from "../../utils/api";
import "./AddUser.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

const antIconDark = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

function AddUser({ navStore, authStore }) {
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");

  const [user, setUser] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  navStore.updateSelectedKey("2");

  let { id } = useParams();
  const editMode = id !== undefined;

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserById(id);
      setUser(user);
      setEmail(user.email);
      setFirstName(user.firstname);
      setLastName(user.lastname);
      setRole(user.role);
      setLoading(false);
    };
    if (editMode) {
      fetchData();
    }
  }, [id, editMode]);

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    try {
      if (editMode) {
        await updateUser(email, firstName, lastName, role, false);
        notification.open({
          message: "User updated",
          description: "User updated successfully",
        });
      } else {
        await inviteUser(email, firstName, lastName, role);
        notification.open({
          message: "User Invited",
          description: "User Invited Successfully",
        });
      }

      navigate("/user");
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="AddUser">
      <div className="AddUser__wrapper">
        <div className="AddUser__container">
          <Button
            className="ProjectPermission__container_back"
            type="text"
            onClick={() => navigate(-1)}
          >
            <LeftOutlined /> Back
          </Button>

          <h2>{editMode ? "Edit User" : "Invite a user"}</h2>

          {editMode && loading && <Spin indicator={antIconDark} />}

          {((editMode && !loading) || !editMode) && (
            <Form
              name="basic"
              initialValues={{
                remember: true,
                user: user,
                email: email,
                firstName: firstName,
                lastName: lastName,
                role: role,
              }}
              autoComplete="off"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                className="AddUser__item"
                label="First name"
                name="firstName"
                rules={[{ required: true, message: "Please enter first name" }]}
              >
                <Input onChange={(e) => setFirstName(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="AddUser__item"
                label="Last name"
                name="lastName"
                rules={[{ required: true, message: "Please enter last name" }]}
              >
                <Input onChange={(e) => setLastName(e.target.value)} />
              </Form.Item>

              <Form.Item
                className="AddUser__item"
                label="Email"
                name="email"
                rules={[{ required: true, message: "Please enter an email" }]}
              >
                <Input
                  disabled={editMode}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className="AddUser__item"
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please select a role" }]}
              >
                <Select defaultValue="" style={{}} onChange={(e) => setRole(e)} disabled={["admin"].indexOf(authStore.userRole) >= 0 ? false : true}>
                  <Option value="">Select Role</Option>
                  {["admin"].indexOf(authStore.userRole) >= 0 && (
                    <Option value="admin">Admin</Option>
                  )}
                  {["admin"].indexOf(authStore.userRole) >= 0 && (
                    <Option value="user">User</Option>
                  )}
                </Select>
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> {editMode ? "Save" : "Invite"}</span>}
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(AddUser);
