import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Button, Select, Input, Form, Row, Col } from "antd";
import { updateBillingInfo } from "../../utils/api";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
);

const stateOptions = [
  { value: null, label: "State" },
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

function CardBillingInfo({  me, refresh, close, onPrev }) {
  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  let address = {};
  if (me?.billing_info) {
    address = me.billing_info.address;
  }

  const [fullName, setFullName] = useState(me?.billing_info?.name);
  const [city, setCity] = useState(address.city);
  const [line1, setLine1] = useState(address.line1);
  const [line2, setLine2] = useState(address.line2);
  const [state, setState] = useState(address.state);
  const [zip, setZip] = useState(address.postal_code);

  const onFinish = async () => {
    try {
      setSubmitting(true);
      const payload = {
        address: {
          city: city,
          country: "US",
          line1: line1,
          line2: line2,
          postal_code: zip,
          state: state,
        },
        name: fullName,
      };
      await updateBillingInfo(payload);
      console.log("Updated billing");
      await refresh();
      close();
    } catch (e) { }
    setSubmitting(false);
  };

  const onZipChange = (inputValue) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
      setZip(inputValue);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: fullName,
      city: city,
      line1: line1,
      line2: line2,
      zip: zip,
      state: state,
    })
  }, [city, form, fullName, line1, line2, state, zip]);

  return (
    <div className="CardSetup">
      <div style={{margin: "20px 0 20px 0"}}>Enter your billing address</div>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          className="AddCardSetup__item"
          placeholder="Full Name"
          name="name"
          style={{ marginBottom: "20px" }}
          rules={[{ required: true, message: "Please enter your full name" }]}
        >
          <Input
            placeholder="Name"
            value={fullName}
            defaultValue={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          className="AddCardSetup__item"
          placeholder="Address Line 1"
          name="line1"
          style={{ marginBottom: "20px" }}
          rules={[
            {
              required: true,
              message: "Please enter your billing address line 1",
            },
          ]}
        >
          <Input
            placeholder="Address Line 1"
            value={line1}
            defaultValue={line1}
            onChange={(e) => setLine1(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          className="AddCardSetup__item"
          placeholder="Address Line 2"
          name="line2"
          style={{ marginBottom: "20px" }}
          rules={[{ required: false }]}
        >
          <Input
            placeholder="Address Line 2"
            value={line2}
            defaultValue={line2}
            onChange={(e) => setLine2(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          className="AddCardSetup__item"
          placeholder="City"
          name="city"
          style={{ marginBottom: "20px" }}
          rules={[
            { required: true, message: "Please enter your billing city" },
          ]}
        >
          <Input
            placeholder="City"
            value={city}
            defaultValue={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Form.Item>

        <Row>
          <Col span={12} style={{ paddingRight: "20px" }}>
            <Form.Item
              className="AddCardSetup__item"
              placeholder="State"
              name="state"
              style={{ marginBottom: "20px" }}
              rules={[{ required: true, message: "Please select a state" }]}
            >
              <Select
                defaultValue={state}
                placeholder="State"
                value={state}
                style={{
                  marginRight: "20px",
                }}
                onChange={(e) => {
                  setState(e);
                }}
                options={stateOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="AddCardSetup__item"
              placeholder="Zip Code"
              name="zip"
              style={{ marginBottom: "20px" }}
              rules={[{ required: true, message: "Please enter your zipcode" }]}
            >
              <Input
                placeholder="Zip Code"
                name="zip"
                value={zip}
                defaultValue={zip}
                onChange={(e) => onZipChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginBottom: "0", marginTop: "20px" }}>
          <div style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              {submitting && <Spin indicator={antIcon} />}
              {!submitting && <span> Save Billing Info</span>}
            </Button>
            {onPrev &&
              <Button type="primary" onClick={onPrev} style={{ float: "left" }}>
                <span> Back</span>
              </Button>
            }
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default observer(CardBillingInfo);
