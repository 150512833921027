import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { DeleteOutlined, LoadingOutlined,  ExclamationCircleOutlined} from "@ant-design/icons";
  
import { Button, Table, Spin, Modal, notification, } from "antd";

import { getProjects, deleteProject } from "../../utils/api";
import { timeSince } from "../../utils/DatetimeFormat";
import { useNavigate } from "react-router-dom";
import { ShareAltOutlined } from "@ant-design/icons";
import {createDefaultProject} from "../../utils/api";

import "./Projects.css";
import { toJS } from "mobx";
import { redirectToGoogleAuth } from "./GSC";

const { confirm } = Modal;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Projects({ authStore, navStore, projectStore }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(toJS(projectStore.projects));
  const [deletings, setDeletings] = useState([]);

  const onAddUrlManuallyClicked = async () => {
    const projectRes = await createDefaultProject();
    navigate(`/project/${projectRes.id}/add-url`);
  };

  const share = (record) => {
    navigate(`/project/${record.id}/permission`);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          <span>
            <button
              className="link"
              onClick={() => {
                navigate(`/project/${record.id}`);
              }}
            >
              {record.name}
            </button>
          </span>
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    // },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner_id",
      render: (owner) => `${owner && owner.firstname ? owner.firstname +" " + owner.lastname : owner.username}`,
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => timeSince(new Date(date)) + " ago ",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <div style={{ width: "110px" }}>
            {authStore.userRole === "admin" && <Button
              style={{ marginRight: "10px" }}
              onClick={() => share(record)}
              icon={<ShareAltOutlined />}
            ></Button>}
            <Button
              style={{ marginRight: "10px" }}
              loading={deletings[record.id]}
              onClick={() => showDeleteConfirm(record)}
              icon={<DeleteOutlined />}
            ></Button>
          </div>
        );
      },
    },
  ];

  const fetchData = useCallback(async () => {
    if (dataSource.length > 0) {
      setLoading(false);
    }
    const projects = await getProjects();
    projects.forEach((x) => {
      x.key = x.id;
    });
    setDataSource(projects);
    projectStore.updateProjects(projects);
    setLoading(false);
  }, [dataSource.length, projectStore]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

const onGoogleSearch = () => {
  redirectToGoogleAuth("default");
};

const showDeleteConfirm = (record) => {
  confirm({
    title: `Are you sure delete project ${record.name}?`,
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      onDelete(record);
    },
    onCancel() {
    },
  });
};

  const onDelete = async (record) => {
    setDeletings((prevDeletings) => {
      const newDeletings = [...prevDeletings];
      newDeletings[record.id] = true;
      return newDeletings;
    });
    await deleteProject(record.id);
    notification.open({
      message: "Project deleted",
    });

    setDeletings((prevDeletings) => {
      const newDeletings = [...prevDeletings];
      newDeletings[record.id] = false;
      return newDeletings;
    });
    fetchData();
  };

  return (
    <div className="Projects">
      <div className="m-2">
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div>
            <div className="Projects__headerbar">
              {dataSource.length > 0 && (
                <Button
                  className="Projects__header_button"
                  type="primary"
                  onClick={() => navigate("/project/add")}
                >
                  Create
                </Button>
              )}
            </div>
            {dataSource.length > 0 && <h1>Projects</h1>}
            {dataSource.length === 0 ? (
              <div className="Projects__new">
               <h1 style={{fontSize: "40px"}}>SEO Hero</h1>
               <h2>SEO Automation.</h2>
               <div  style={{marginBottom: "20px"}}>
                  <Button className="google-console-btn" onClick={onGoogleSearch}>
                    <img
                      class="google-icon"
                      alt="Google Search Console"
                      src="/Google__G__logo.svg"
                      width="20px"
                      style={{ marginRight: "10px" }}
                    />
                    Connect to Google Search Console
                  </Button>
                  <div style={{marginTop:"20px", marginBottom: "20px"}}>or</div>
                  <div>
                    <Button type="text" onClick={onAddUrlManuallyClicked}>Add URLs Manually</Button>
                  </div>
                </div>
              </div>
            ) : (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 50 }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(Projects);
