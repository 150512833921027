import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  LoadingOutlined,
  ExclamationCircleFilled,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  Button,
  Table,
  Spin,
  notification,
  Modal,
  Input,
  Row,
  Col,
  Switch,
  Popover,
  Select,
} from "antd";

import {
  getUrls,
  getProject,
  updateProject,
  updateUrl,
  deleteUrl,
  generateUrlGpt,
  publishUrls,
  unPublishUrls,
  deleteUrls,
  updateProjectOauth,
  getProjectOauth,
  deleteProjectOauth,
  loadRoles,
} from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  RedoOutlined,
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  CodeOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { CSVLink } from "react-csv";
import CsvImport from "./CsvImport";
import GoogleSearchConsoleAuth from "./GSC";

import "./ProjectDetail.css";
const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const antIconWhite = (
  <LoadingOutlined
    style={{ fontSize: 20, color: "white", marginRight: "10px" }}
    spin
  />
);

const SHOPIFY_CLIENT_ID = process.env.REACT_APP_ENV === "development" ? "affaf156625776535ada246b3982ecad": "affaf156625776535ada246b3982ecad";
const SHOPIFY_STORE_URL = "https://quickstart-3d2af502.myshopify.com/admin/oauth/redirect_from_cli";


function ProjectDetail({ authStore, navStore }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTextEditModalOpen, setIsTextEditModalOpen] = useState(false);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isGSCModalOpen, setIsGSCModalOpen] = useState(false);
  const [isImportUrlOpen, setIsImportUrlOpen] = useState(false);

  const [editTextType, setEditTextType] = useState("");
  const [editTextValue, setEditTextValue] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [editRecord, setEditRecord] = useState({});
  const [editKeywords, setEditKeywords] = useState("");
  const [gscData, setGscData] = useState([]);
  const [propertyName, setPropertyName] = useState(null);
  const [savingPropertyName, setSavingPropertyName] = useState(false);

  const [beginningWord, setBeginningWord] = useState("");
  const [endingCTA, setEndingCTA] = useState("");
  const [brandName, setBrandName] = useState("");
  const [project, setProject] = useState();
  const [projectOauth, setProjectOauth] = useState(null);
  const [projectOauthShopify, setProjectOauthShopify] = useState(null);

  const [loadings, setLoadings] = useState([]);
  const [deletings, setDeletings] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [editCopyKeywords, setEditCopyKeywords] = useState("");

  const [autoPopulate, setAutoPopulate] = useState(false);
  const [autoPublish, setAutoPublish] = useState(false);
  const [hideClicks, setHideClicks] = useState(false);
  const [limitReached, setLimitReached] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [timerIds, setTimerIds] = useState([]);

  const { id } = useParams();
  const { confirm } = Modal;

  const src = "https://app.seohero.ai/dist/app.bundle.js";
  const beginningWordChanged = debounce(async (e) => {
    setBeginningWord(e.target.value);
    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      e.target.value,
      project.brand_name,
      project.endingCTA,
      project.auto_populate,
      project.auto_publish,
      project.sitemaps
    );
    setProject({ ...project, beginning_word: e.target.value });
  }, 500);

  const endingCTAChanged = debounce(async (e) => {
    setEndingCTA(e.target.value);
    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      project.beginning_word,
      project.brand_name,
      e.target.value,
      project.auto_populate,
      project.auto_publish,
      project.sitemaps
    );
    setProject({ ...project, ending_cta: e.target.value });
  }, 500);

  const brandNameChanged = debounce(async (e) => {
    setBrandName(e.target.value);
    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      project.beginning_word,
      e.target.value,
      project.ending_cta,
      project.auto_populate,
      project.auto_publish,
      project.sitemaps
    );
    setProject({ ...project, brand_name: e.target.value });
  }, 500);

  const onTableChange = (pagination) => {
    setPageSize(pagination.pageSize);
  };

  /* const publish = async (record, publish) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[record.id] = "publish";
      return newLoadings;
    });
    await updateUrl(
      record.id,
      record.url,
      record.keywords,
      record.copy_keywords,
      record.title,
      record.meta,
      record.seo_copy,
      publish
    );

    notification.open({
      message: publish ? "URL published" : "URL unpublished",
    });

    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[record.id] = false;
      return newLoadings;
    });
    fetchData();
  }; */

  const recompute = async (text_type, record) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[record.id] = text_type;
      return newLoadings;
    });
    await generateUrlGpt(record.project_id, record.id, text_type);
    notification.open({
      message: "Text generated successfully",
    });

    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[record.id] = false;
      return newLoadings;
    });
    fetchData();
  };

  const onEdit = async (text_type, record) => {
    setEditTextType(text_type);
    setEditRecord(record);
    setEditTextValue(record[text_type]);
    setIsTextEditModalOpen(true);
  };

  const onDelete = async (record) => {
    setDeletings((prevDeletings) => {
      const newDeletings = [...prevDeletings];
      newDeletings[record.id] = true;
      return newDeletings;
    });
    await deleteUrl(record.id);
    notification.open({
      message: "URL deleted",
    });

    setDeletings((prevDeletings) => {
      const newDeletings = [...prevDeletings];
      newDeletings[record.id] = false;
      return newDeletings;
    });
    fetchData();
  };

  const showModal = (record, keyword) => {
    setKeyword(keyword);
    setIsModalOpen(true);
    setEditRecord(record);
    setEditKeywords(
      record.keywords
        ?.map((elem) => {
          if (elem.includes(" ")) {
            return `"${elem}"`;
          } else {
            return elem;
          }
        })
        .join("\n")
    );
    setEditCopyKeywords(
      record.copy_keywords
        ?.map((elem) => {
          if (elem.includes(" ")) {
            return `"${elem}"`;
          } else {
            return elem;
          }
        })
        .join("\n")
    );
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const keywords = [
      ...new Set(
        editKeywords?.split("\n").flatMap((str) =>
          str
            .toLowerCase()
            .match(/'([^']+)'|"([^"]+)"|\S+/g)
            .map((str) => str.replace(/["']/g, ""))
        )
      ),
    ];
    const copyKeywords = [
      ...new Set(
        editCopyKeywords?.split("\n").flatMap((str) =>
          str
            .toLowerCase()
            .match(/'([^']+)'|"([^"]+)"|\S+/g)
            .map((str) => str.replace(/["']/g, ""))
        )
      ),
    ];
    await updateUrl(
      editRecord.id,
      editRecord.url,
      keyword === "copy_keyword" ? editRecord.keywords : keywords,
      keyword === "copy_keyword" ? copyKeywords : editRecord.copy_keywords,
      editRecord.title,
      editRecord.meta,
      editRecord.seo_copy,
      editRecord.published
    );
    await fetchData();
    setConfirmLoading(false);
    setIsModalOpen(false);
  };

  const handleTextEditOk = async () => {
    setConfirmLoading(true);
    let title = editRecord.title;
    let meta = editRecord.meta;
    let seo_copy = editRecord.seo_copy;

    if (editTextType === "title") {
      title = editTextValue;
    } else if (editTextType === "meta") {
      meta = editTextValue;
    } else if (editTextType === "seo_copy") {
      seo_copy = editTextValue;
    }
    await updateUrl(
      editRecord.id,
      editRecord.url,
      editRecord.keywords,
      editRecord.copy_keywords,
      title,
      meta,
      seo_copy,
      editRecord.published
    );
    await fetchData();
    setConfirmLoading(false);
    setIsTextEditModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsTextEditModalOpen(false);
    setIsGSCModalOpen(false);
    setSavingPropertyName(false);
  };

  const columns = [
    {
      title: "Url",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "75px",
      hidden: hideClicks,
    },
    {
      title: "Keywords (3-4 recommended)",
      dataIndex: "keywords",
      key: "keywords",
      ellipsis: true,
      render: (_, record) => {
        return (
          <div className="ProjectDetail_keyword_container">
            {record.keywords?.map((x) => {
              return (
                <div key={x} className="ProjectDetail_keyword">
                  {x}
                </div>
              );
            })}
            <div>
              <Button
                style={{
                  padding: "6px",
                  margin: "6px",
                }}
                onClick={() => showModal(record, "keyword")}
              >
                <EditOutlined />
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Title Tag",
      key: "title",
      render: (_, record) => {
        return (
          <div style={{ minWidth: "200px" }}>
            {record.title}

            <div>
              <Button
                style={{ marginRight: "10px" }}
                loading={loadings[record.id] === "title"}
                onClick={() => recompute("title", record)}
                icon={<RedoOutlined />}
              ></Button>
              <Button
                style={{ marginRight: "10px" }}
                loading={deletings[record.id]}
                onClick={() => onEdit("title", record)}
                icon={<EditOutlined />}
              ></Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Meta Description",
      key: "meta",
      render: (_, record) => {
        return (
          <div style={{ minWidth: "180px" }}>
            {record.meta}
            <div>
              <Button
                style={{ marginRight: "10px" }}
                loading={loadings[record.id] === "meta"}
                onClick={() => recompute("meta", record)}
                icon={<RedoOutlined />}
              ></Button>
              <Button
                style={{ marginRight: "10px" }}
                loading={deletings[record.id]}
                onClick={() => onEdit("meta", record)}
                icon={<EditOutlined />}
              ></Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Copy Keywords",
      dataIndex: "copy_keywords",
      key: "copy_keywords",
      render: (_, record) => {
        return (
          <div className="ProjectDetail_keyword_container">
            {record.copy_keywords?.map((x) => {
              return (
                <div key={x} className="ProjectDetail_keyword">
                  {x}
                </div>
              );
            })}
            <div>
              <Button
                style={{
                  padding: "6px",
                  margin: "6px",
                }}
                onClick={() => showModal(record, "copy_keyword")}
              >
                <EditOutlined />
              </Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Seo Copy",
      key: "seo_copy",
      render: (_, record) => {
        return (
          <div style={{ minWidth: "180px" }}>
            {record.seo_copy}
            <div>
              <Button
                style={{ marginRight: "10px" }}
                loading={loadings[record.id] === "seo-copy"}
                onClick={() => recompute("seo-copy", record)}
                icon={<RedoOutlined />}
              ></Button>
              <Button
                style={{ marginRight: "10px" }}
                loading={deletings[record.id]}
                onClick={() => onEdit("seo_copy", record)}
                icon={<EditOutlined />}
              ></Button>
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <div style={{ width: "110px" }}>
            {/* <Button
              style={{ marginRight: "10px" }}
              loading={loadings[record.id] === "publish"}
              onClick={() => publish(record, !record.published)}
              icon={record.published ? <CheckOutlined /> : <SendOutlined />}
            ></Button> */}
            <Button
              style={{ marginRight: "10px" }}
              loading={deletings[record.id]}
              onClick={() => onDelete(record)}
              icon={<DeleteOutlined />}
            ></Button>
          </div>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const csvHeaders = [
    { label: "Url", key: "url" },
    { label: "Clicks", key: "clicks" },
    { label: "Keywords (3-4 recommended)", key: "keywords" },
    { label: "Title tag", key: "title" },
    { label: "Meta Description", key: "meta" },
    { label: "Copy Keywords", key: "copy_keywords" },
    { label: "Seo Copy", key: "seo_copy" },
  ];
  const fetchData = useCallback(async () => {
    if (dataSource.length > 0) {
      setLoading(false);
    }
    const project_ = getProject(id);
    const projectDetails = await getUrls(id);
    const project = await project_;

    console.log("Setting is processing ", project[0].is_processing);
    setIsProcessing(project[0].is_processing);

    try {
      const projectOauth = await getProjectOauth(project[0].id, "google");
      setProjectOauth(projectOauth);
    } catch {
      setProjectOauth(null);
    }

    try {
      const projectOauthShopify = await getProjectOauth(
        project[0].id,
        "shopify"
      );
      setProjectOauthShopify(projectOauthShopify);
    } catch {
      setProjectOauthShopify(null);
    }

    setProject(project[0]);
    setBeginningWord(
      project[0].beginning_word !== null
        ? project[0].beginning_word
        : "Discover"
    );
    setEndingCTA(
      project[0].ending_cta !== null
        ? project[0].ending_cta
        : "Enjoy Free Shipping & Returns"
    );
    setBrandName(
      project[0].brand_name !== null ? project[0].brand_name : "| BRAND NAME"
    );
    setAutoPopulate(project[0].auto_populate);
    setAutoPublish(project[0].auto_publish);
    let clicks = 0;
    projectDetails.forEach((x) => {
      x.key = x.id;
      if (x.clicks) {
        clicks += parseInt(x.clicks);
      }
    });
    if (clicks > 0) {
      console.log(`Total clicks: ${clicks}`);
      setHideClicks(false);
    }
    projectDetails.sort((x) => -x.clicks);

    setDataSource(projectDetails);
    setLoading(false);
  }, [id, dataSource.length]);

  useEffect(() => {
    console.log("use effect is processing ", project?.is_processing);

    if (isProcessing) {
      timerIds.forEach((id) => clearInterval(id));
      setTimerIds([]);

      const timer = setInterval(() => {
        fetchData();
      }, 5000);
      setTimerIds((prev) => [...prev, timer]);
    } else {
      timerIds.forEach((id) => clearInterval(id));
      setTimerIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  const loadRolesAsync = useCallback(async () => {
    // eslint-disable-next-line no-unused-vars
    const { me, roles } = await loadRoles();
    setLimitReached(me.meter.urls / me.plan_limits >= 1);
  }, []);

  useEffect(() => {
    fetchData();
    loadRolesAsync();
  }, [fetchData, loadRolesAsync]);

  useEffect(() => {
    async function getGSCUrl() {
      if (
        localStorage.hasOwnProperty("googleAccessToken") &&
        localStorage.getItem("googleAccessToken") !== null
      ) {
        try {
          const accessToken = localStorage.getItem("googleAccessToken");
          const response = await fetch(
            `https://www.googleapis.com/webmasters/v3/sites`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              method: "GET",
            }
          );
          const sitesData = await response.json();
          const siteUrls = sitesData.siteEntry.map((site) => site.siteUrl);

          setIsGSCModalOpen(true);
          setGscData(siteUrls);

          try {
            const projectOauth = await getProjectOauth(project[0].id, "google");
            setProjectOauth(projectOauth);
          } catch {
            setProjectOauth(null);
          }

          localStorage.removeItem("googleAccessToken");
        } catch (e) {}
      }
    }
    getGSCUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePropertyChange = async (propertyName, option) => {
    setPropertyName(propertyName);
  };

  const addProperty = async () => {
    try {
      setSavingPropertyName(true);
      console.log(id, projectOauth, propertyName);

      await updateProjectOauth(id, projectOauth[0].id, propertyName);
      notification.open({
        message: "Property Added",
      });
      fetchData();
      setIsGSCModalOpen(false);
      setSavingPropertyName(false);
    } catch (e) {}
  };

  const onDisconnect = async () => {
    try {
      await deleteProjectOauth(id, projectOauth[0].id);
      setProjectOauth(null);
      notification.open({
        message: "Disconnected",
      });
      fetchData();
    } catch (e) {}
  };

  const onAutoPopulateChange = async (checked) => {
    setAutoPopulate(checked);
    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      project.beginning_word,
      project.brand_name,
      project.endingCTA,
      checked,
      project.auto_publish,
      project.sitemaps
    );
    setProject({ ...project, auto_populate: checked });
    await fetchData();
  };

  const onAutoPublishChange = async (checked) => {
    setAutoPublish(checked);
    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      project.beginning_word,
      project.brand_name,
      project.endingCTA,
      checked,
      project.auto_populate,
      project.sitemaps
    );
    setProject({ ...project, auto_publish: checked });
  };

  const gotoAccount = () => {
    navigate("/account");
  };

  const [selectedProjectRowKeys, setSelectedProjectRowKeys] = useState([]);
  const projectRowSelection = {
    hideSelectAll: false,
    onChange: (selectedProjectRowKeys) => {
      setSelectedProjectRowKeys(selectedProjectRowKeys);
    },
  };

  const content = (
    <Row>
      <Col span={8} style={{ paddingRight: "20px" }}>
        <Input
          addonBefore="Brand Name"
          onChange={(value) => {
            brandNameChanged(value);
          }}
          defaultValue={brandName}
        />
      </Col>
      <Col span={8} style={{ paddingRight: "20px" }}>
        <Input
          addonBefore="Beginning Word"
          onChange={(value) => {
            beginningWordChanged(value);
          }}
          defaultValue={beginningWord}
        />
      </Col>
      <Col span={8} style={{ paddingRight: "20px" }}>
        <Input
          addonBefore="Ending CTA"
          onChange={(value) => {
            endingCTAChanged(value);
          }}
          defaultValue={endingCTA}
        />
      </Col>
    </Row>
  );

  const showConfirmDeleteSelected = () => {
    confirm({
      title: `Are you sure you want to delete ${selectedProjectRowKeys.length} urls?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        await deleteUrls(id, selectedProjectRowKeys);
        notification.open({
          message: "URLs deleted",
        });

        setSelectedProjectRowKeys([]);
        fetchData();
      },
      onCancel() {},
    });
  };

  const showConfirmPublishSelected = () => {
    confirm({
      title: `Are you sure you want to publish ${selectedProjectRowKeys.length} urls?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        await publishUrls(id, selectedProjectRowKeys);
        notification.open({
          message: "URLs published",
        });

        setSelectedProjectRowKeys([]);
        fetchData();
      },
      onCancel() {},
    });
  };
  const showConfirmUnPublishSelected = () => {
    confirm({
      title: `Are you sure you want to unpublish ${selectedProjectRowKeys.length} urls?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        await unPublishUrls(id, selectedProjectRowKeys);
        notification.open({
          message: "URLs unpublished",
        });

        setSelectedProjectRowKeys([]);
        fetchData();
      },
      onCancel() {},
    });
  };

  const closeImportModelAndRefresh = () => {
    fetchData();
    setIsImportUrlOpen(false);
  };

  const onShopifyConnect = () => {
    localStorage.setItem("project", id);
    document.location = `${SHOPIFY_STORE_URL}?client_id=${SHOPIFY_CLIENT_ID}`;
  };

  const onShopifyDisconnect = async () => {
    try {
      await deleteProjectOauth(id, projectOauthShopify[0].id);
      setProjectOauthShopify(null);
      notification.open({
        message: "Disconnected",
      });
      fetchData();
    } catch (e) {}
  };

  return (
    <div className="ProjectDetail">
      <Modal
        title={`Edit${keyword === "copy_keyword" ? " copy" : ""}  keywords`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okButtonProps={{
          disabled: editKeywords?.trim().length === 0,
        }}
        okText="Update keywords"
      >
        <div>Please add a keyword per line (3-4 recommended)</div>
        <TextArea
          rows={5}
          value={keyword === "copy_keyword" ? editCopyKeywords : editKeywords}
          onChange={(e) =>
            keyword === "copy_keyword"
              ? setEditCopyKeywords(e.target.value)
              : setEditKeywords(e.target.value)
          }
          placeholder="Keywords"
        />
      </Modal>

      <Modal
        title="Copy Scripts"
        open={isCodeModalOpen}
        footer={[]}
        onCancel={() => setIsCodeModalOpen(false)}
      >
        <div>Add the following script to your web page</div>
        <code>
          &lt;script src="{src}" class="{id}"
          id="seoHeroProjectId"&gt;&lt;/script&gt;
        </code>
        <div style={{ marginTop: "40px" }}>
          Add the following code to a location where you'd like to add your SEO
          copy
        </div>
        <code>&lt;div id="seoHeroCopy"&gt;&lt;/div&gt;</code>
      </Modal>

      <Modal
        title="Import URLs"
        open={isImportUrlOpen}
        footer={[]}
        onCancel={() => setIsImportUrlOpen(false)}
      >
        <CsvImport projectId={id} closeModal={closeImportModelAndRefresh} />
      </Modal>

      <Modal
        title={``}
        className="GSCModal"
        open={isGSCModalOpen}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={null}
        width={400}
        style={{ minHeight: "100px" }}
      >
        <Select
          placeholder="Select a property"
          options={gscData.map((key) => {
            return { label: key.replace("sc-domain:", ""), value: key };
          })}
          optionLabelProp="label"
          onChange={handlePropertyChange}
          defaultOpen={true}
          autoFocus={true}
          size={"large"}
          style={{
            width: "100%",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
          dropdownStyle={{
            margin: "1rem",
            backgroundColor: "white",
          }}
        ></Select>

        <Button
          type="secondary"
          onClick={addProperty}
          block
          disabled={propertyName == null || savingPropertyName}
        >
          {
            <span>
              {savingPropertyName && <Spin indicator={antIconWhite}></Spin>}
              Save
            </span>
          }
        </Button>

        {/* {showGSCTable && (
          <>
            <Table
              rowSelection={rowSelection}
              dataSource={gscTableData}
              columns={gscColumn}
              pagination={pagination}
              rowKey={(record) => record.keys[0]}
              style={{ tableLayout: "fixed" }}
              onChange={handleTableChange}
              size="small"
            />
            <Button
              type="secondary"
              onClick={addUrls}
              block
              disabled={selectedRowKeys.length > 0 ? false : true}
            >
              {urlSubmitting && <Spin indicator={antIcon} />}
              {!urlSubmitting && <span> Add URLs</span>}
            </Button>
          </>
        )} */}
      </Modal>

      <Modal
        title={`Edit ${editTextType.replace("_", "")}`}
        open={isTextEditModalOpen}
        onOk={handleTextEditOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Update"
      >
        <TextArea
          rows={5}
          value={editTextValue}
          onChange={(e) => setEditTextValue(e.target.value)}
          placeholder="text"
        />
      </Modal>

      <div className="m-2">
        {loading ? (
          <Spin indicator={antIcon} />
        ) : (
          <div>
            <div className="ProjectDetail__headerbar">
              <Row>
                <Col span={10}>
                  {selectedProjectRowKeys.length > 0 && (
                    <React.Fragment>
                      <Button
                        type="outline"
                        style={{ marginLeft: "20px", marginRight: "5px" }}
                        onClick={showConfirmPublishSelected}
                      >
                        <SendOutlined /> Publish
                      </Button>
                      <Button
                        type="outline"
                        style={{ marginRight: "5px" }}
                        onClick={showConfirmDeleteSelected}
                      >
                        <DeleteOutlined />
                        Delete
                      </Button>
                      <Button
                        type="outline"
                        style={{ marginRight: "30px" }}
                        onClick={showConfirmUnPublishSelected}
                      >
                        <CheckOutlined /> Unpublish
                      </Button>
                    </React.Fragment>
                  )}

                  {selectedProjectRowKeys.length === 0 &&
                    dataSource.length > 0 && (
                      <>
                        {isProcessing ? (
                          <>
                            <Spin
                              indicator={antIcon}
                              style={{ marginRight: "5px" }}
                            />{" "}
                            Populating{" "}
                          </>
                        ) : (
                          <Popover
                            placement="topLeft"
                            content={content}
                            title="Title"
                          >
                            <Button type="primary">
                              {brandName} | {beginningWord} | {endingCTA}
                            </Button>
                          </Popover>
                        )}
                      </>
                    )}
                </Col>
                <Col span={14}>
                  {dataSource.length > 0 && (
                    <React.Fragment>
                      <div style={{ float: "left" }}>
                        <div style={{ marginBottom: "5px" }}>
                          <Switch
                            defaultChecked={false}
                            checked={autoPopulate}
                            onChange={onAutoPopulateChange}
                          />
                          <span style={{ paddingLeft: "7px" }}>
                            Auto Populate
                          </span>
                        </div>
                        <div>
                          <Switch
                            defaultChecked={false}
                            checked={autoPublish}
                            onChange={onAutoPublishChange}
                          />
                          <span style={{ paddingLeft: "7px" }}>
                            Auto Publish
                          </span>
                        </div>
                      </div>

                      <CSVLink
                        filename={`project-${project.name}.csv`}
                        data={dataSource}
                        headers={csvHeaders}
                        className="btn btn-primary"
                      >
                        <Button
                          style={{
                            float: "right",
                            fontSize: "25px",
                            lineHeight: "25px",
                            padding: "5px 4px",
                          }}
                          type="text"
                        >
                          <DownloadOutlined />
                        </Button>
                      </CSVLink>

                      <Button
                        style={{
                          float: "right",
                          fontSize: "25px",
                          lineHeight: "25px",
                          padding: "5px 4px",
                        }}
                        type="text"
                        onClick={() => setIsImportUrlOpen(true)}
                      >
                        <UploadOutlined />
                      </Button>

                      <Button
                        style={{
                          float: "right",
                          fontSize: "25px",
                          lineHeight: "25px",
                          padding: "5px 4px",
                        }}
                        type="text"
                        onClick={() => setIsCodeModalOpen(true)}
                      >
                        <CodeOutlined />
                      </Button>

                      <Button
                        style={{ float: "right" }}
                        className="ProjectDetail__header_button"
                        type="primary"
                        onClick={() => navigate(`/project/${id}/add-url`)}
                      >
                        Add URL
                      </Button>

                      {projectOauth &&
                      projectOauth.length > 0 &&
                      projectOauth[0].connected ? (
                        <Button
                          style={{ float: "right", marginRight: "10px" }}
                          onClick={onDisconnect}
                        >
                          Disconnect GSC
                        </Button>
                      ) : (
                        <GoogleSearchConsoleAuth
                          id={id}
                          styleObj={{ float: "right" }}
                        />
                      )}

                      {projectOauthShopify &&
                      projectOauthShopify.length > 0 &&
                      projectOauthShopify[0].connected ? (
                        <div>
                          <Button
                            style={{ float: "right", marginRight: "10px" }}
                            onClick={onShopifyDisconnect}
                          >
                            Disconnect Shopify
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            style={{ float: "right", marginRight: "10px" }}
                            onClick={onShopifyConnect}
                          >
                            Connect to Shopify
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </div>
            {!isGSCModalOpen && (
              <div>
                {dataSource.length === 0 ? (
                  <div className="ProjectDetail__new">
                    <div className="Project__new_img"></div>
                    <div className="mt-2">
                      Looks like you haven't added any URLs.
                    </div>
                    {!projectOauth && (
                      <GoogleSearchConsoleAuth
                        id={id}
                        styleObj={{
                          float: "none",
                          height: "40px",
                          marginRight: "30px",
                        }}
                      />
                    )}
                    <Button
                      className="mt-1"
                      type="primary"
                      size="large"
                      onClick={() => navigate(`/project/${id}/add-url`)}
                    >
                      Add a new URL
                    </Button>
                  </div>
                ) : (
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      ...projectRowSelection,
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: pageSize }}
                    onChange={onTableChange}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {limitReached && (
        <div className="ProjectDetail__limitReached">
          URL limit reached! Upgrade your{" "}
          <Button
            type="text"
            style={{ margin: "0px", padding: "0px", color: "#cc0000" }}
            onClick={gotoAccount}
          >
            here
          </Button>
        </div>
      )}
    </div>
  );
}

export default observer(ProjectDetail);
