import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Input, Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { confirmForgotPassword, forgotPassword } from "./cognito";

import "./ForgotPassword.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);

const antIconResending = <LoadingOutlined style={{ fontSize: 16 }} spin />;
function ForgotPassword({ authStore }) {
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState("");
  const [passwordStep, setPasswordStep] = useState(false);

  const [newPassword, setNewPassword] = useState(true);
  const [newPassword2, setNewPassword2] = useState(true);

  const [userName, setUserName] = useState("");
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    if (!passwordStep) {
      try {
        await forgotPassword(userName);
        setPasswordStep(true);
      } catch (e) {
        setError(e.message);
      }
    } else {
      if (newPassword !== newPassword2) {
        setError("Password mismatch");
        setSubmitting(false);
        return;
      }
      try {
        await confirmForgotPassword(userName, code, newPassword);
        navigate("/login");
      } catch (e) {
        setError(e.message);
      }
    }
    setSubmitting(false);
  };

  const resendCode = async () => {
    setResending(true);
    await forgotPassword(userName);
    setResending(false);
  };

  return (
    <div className="ForgotPassword">
      <div className="ForgotPassword__wrapper">
        <div className="ForgotPassword__container">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
          >
            {!passwordStep && (
              <React.Fragment>
                <Form.Item
                  className="ForgotPassword__item"
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please enter your username!" },
                  ]}
                >
                  <Input onChange={(e) => setUserName(e.target.value)} />
                </Form.Item>
              </React.Fragment>
            )}

            {passwordStep && (
              <React.Fragment>
                <h2>Set new password</h2>
                <Form.Item
                  className="ForgotPassword__item"
                  label="Code"
                  name="code"
                  rules={[{ required: true, message: "Please enter code!" }]}
                >
                  <Input onChange={(e) => setCode(e.target.value)} />
                </Form.Item>

                <Form.Item
                  className="ForgotPassword__item"
                  label="New Password"
                  name="newPassword"
                  rules={[
                    { required: true, message: "Please enter a new password!" },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  className="ForgotPassword__item"
                  label="Re-enter Password"
                  name="newPassword1"
                  rules={[
                    {
                      required: true,
                      message: "Please re-enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => setNewPassword2(e.target.value)}
                  />
                </Form.Item>
              </React.Fragment>
            )}
            {error && <div className="error">{error}</div>}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && (
                  <span>
                    {" "}
                    {passwordStep ? "Set Password" : "Send one time reset code"}
                  </span>
                )}
              </Button>

              {passwordStep && (
                <div className="ForgotPassword__resend">
                  {resending && <Spin indicator={antIconResending} />}{" "}
                  <button className="link" onClick={() => resendCode()}>Resend one-time reset code</button>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(ForgotPassword);
