import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Spin, InputNumber } from "antd";
import { getPrompts, updatePrompt } from "../../utils/api";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

import "./Settings.css";
import TextArea from "antd/lib/input/TextArea";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
function Settings({ navStore, authStore }) {
  const [prompts, setPrompts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const prompts = await getPrompts();
    setPrompts(prompts);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const promptChanged = debounce(
    async (id, textType, value, configs) => {
      await updatePrompt(id, textType, value, configs);
    },
    500,
    {}
  );

  const onTemperatureChange = (prompt, value) => {
    prompt.configs.temperature = value;
    promptChanged(prompt.id, prompt.text_type, prompt.prompt, prompt.configs);
  }

  const onMaxTokenChange = (prompt, value) => {
    prompt.configs.max_tokens = value;
    promptChanged(prompt.id, prompt.text_type, prompt.prompt, prompt.configs);
  }

  const titleCase = (string) => {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  };
  return (
    <div className="Settings m-2">
      {loading && <Spin indicator={antIcon} />}
      {!loading && (
        <div>
          {prompts.map((x) => (
            <div className="Settings__prompt" key={x.text_type}>
              <h2>{titleCase(x.text_type.replace("-", " "))}</h2>

              <div className="Settings__configs">
                <div className="Settings__configs-item">
                  Temperature:{" "}
                  <InputNumber
                    min={0}
                    max={1}
                    defaultValue={x.configs.temperature}
                    step={0.05}
                    onChange={value => onTemperatureChange(x, value)}
                  />
                </div>

                <div className="Settings__configs-item">
                  Max Tokens:{" "}
                  <InputNumber
                    min={0}
                    max={4096}
                    step={5}
                    defaultValue={x.configs.max_tokens}
                    onChange={value => onMaxTokenChange(x, value)}
                  />
                </div>
              </div>
              <div>
                <TextArea
                  rows={10}
                  onChange={(e) =>
                    promptChanged(x.id, x.text_type, e.target.value, x.configs)
                  }
                  placeholder={x.text_type}
                  defaultValue={x.prompt}
                ></TextArea>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default observer(Settings);
