export const config =
  process.env.REACT_APP_ENV === "production"
    ? {
        STRIPE_PUBLISHABLE_KEY:
          "pk_live_51LS4j6ANTdz0PriYgDrmJ8vgGqcdd62TTLzrB1Vd9axurFUZEKIxRqccpIt4x92lFP1xt9JYjTl06uMkIu0goEeG00qcVwGQNY",
      }
    : {
        STRIPE_PUBLISHABLE_KEY:
          "pk_test_51Kpm5bG89Rlyeegyp157vtFWJzyT8Maahk8lpB4Og6qOOCY1wLpvuv75Gp6XXdaviFqx51JlTYz2WQWBh4qpk0Bu00slR7NJyz",
      };
