import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/security/Login";
import Signup from "./components/security/Signup";
import Logout from "./components/security/Logout";
import ForgotPassword from "./components/security/ForgotPassword";
import RequiredAuth from "./components/security/RequiredAuth";
import User from "./components/security/User";
import Settings from "./components/security/Settings";
import AddUser from "./components/security/AddUser";
import AddProject from "./components/project/AddProject";
import AddUrl from "./components/project/AddUrl";
import Account from "./components/account/Account";
import ProjectDetail from "./components/project/ProjectDetail";
import ProjectPermission from "./components/project/ProjectPermission";
import CsvImport from "./components/project/CsvImport";
import Home from "./view/Home";
import { ConfigProvider, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { getUser } from "./utils/api";

import { observer } from "mobx-react-lite";

import { Layout } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GSC } from "./components/project/GSC";
import { Shopify } from "./components/project/Shopify";
import LoginCallback from "./components/security/LoginCallback";

const { Header, Content } = Layout;

function App({ authStore, navStore, projectStore }) {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);

  const fetchData = useCallback(async () => {
    const user = await getUser();
    authStore.updateUser(user);
  }, [authStore]);

  useEffect(() => {
    const items = [
      {
        label: "Projects",
        key: "projects",
      },
    ];
    if (authStore.userRole === "admin") {
      items.push({
        label: "Account",
        key: "account",
      });
      items.push({
        label: "Users",
        key: "user",
      });
    }

    if (authStore.userRole === "admin" && authStore.user && authStore.user.organization_id === 7) {
      items.push({
        label: "Settings",
        key: "settings",
      });
    }

    items.push({
      label: "Logout",
      key: "logout",
    });

    setItems(items);
  }, [authStore.userRole, authStore.user]);

  useEffect(() => {
    if (authStore.session !== null) {
      fetchData();
    }
  }, [authStore.session, fetchData]);

  const handleMenuClick = (e) => {
    if (e.key === "projects") {
      document.location = "/";
    } else {
      document.location = "/" + e.key;
    }
    setOpen(false);
  };
  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1b263b",
        },
      }}
    >
      <div className="App">
        <Layout>
          <Header className="header">
            <div className="App__logo">
              <h1>
                <a href="/">SEO Hero</a>
              </h1>
            </div>
            {authStore.authenticated && (
              <div className="App__menu">
                <Dropdown
                  className="App__menu_icon"
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={open}
                >
                  <SettingOutlined className="App__menu_icon" />
                </Dropdown>
              </div>
            )}

            {!authStore.authenticated && (
              <div className="App__menu">
                {/* {document.location.pathname !== "/login" ? ( */}
                <Button
                  type="text"
                  className="login"
                  onClick={() => (document.location = "/signup")}
                >
                  Sign Up
                </Button>
                {/* ) : ( */}
                <Button
                  type="text"
                  className="login"
                  onClick={() => (document.location = "/login")}
                >
                  Login
                </Button>
                {/* )} */}
              </div>
            )}
          </Header>

          <Content className="content">
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <Home
                          authStore={authStore}
                          navStore={navStore}
                          projectStore={projectStore}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/project/add"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <AddProject authStore={authStore} navStore={navStore} />
                      }
                    />
                  }
                />
                <Route
                  path="/project/:id/add-url"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <AddUrl authStore={authStore} navStore={navStore} />
                      }
                    />
                  }
                />
                <Route
                  path="/project/:id"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <ProjectDetail
                          authStore={authStore}
                          navStore={navStore}
                          projectStore={projectStore}
                        />
                      }
                    />
                  }
                />
                <Route
                  path="/account"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <Account authStore={authStore} navStore={navStore} />
                      }
                    />
                  }
                />
                <Route
                  path="/project/:id/permission"
                  element={
                    <RequiredAuth
                      authStore={authStore}
                      children={
                        <ProjectPermission
                          authStore={authStore}
                          navStore={navStore}
                        />
                      }
                    />
                  }
                />

                <Route
                  path="/user"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <User navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />
                <Route
                  path="/user/add"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <AddUser navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />
                <Route
                  path="/user/edit/:id"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <AddUser navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <Settings navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />
                <Route
                  path="/callback"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <GSC navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />
                <Route
                  path="/shopify-callback"
                  element={
                    <RequiredAuth authStore={authStore}>
                      <Shopify navStore={navStore} authStore={authStore} />
                    </RequiredAuth>
                  }
                />

                <Route
                  path="/login-callback"
                  element={
                      <LoginCallback navStore={navStore} authStore={authStore} />
                  }
                />
                   <Route
                  path="/import"
                  element={
                      <CsvImport projectId={1} />
                  }
                />
                <Route
                  path="/login"
                  element={<Login authStore={authStore} navStore={navStore} />}
                />
                <Route
                  path="/signup"
                  element={<Signup authStore={authStore} navStore={navStore} />}
                />
                <Route
                  path="/logout"
                  element={<Logout authStore={authStore} />}
                />
                <Route
                  path="/forgot"
                  element={<ForgotPassword authStore={authStore} />}
                />
              </Routes>
            </BrowserRouter>
          </Content>
        </Layout>
      </div>
    </ConfigProvider>
  );
}

export default observer(App);
