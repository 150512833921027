import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { loginCallback } from "./login_callback";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function LoginCallback({ authStore }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetch = async () => {
      const session = await loginCallback(searchParams.get("code"));
      authStore.setSession(session);
      navigate("/");
    };

    fetch();
  }, [authStore, navigate, searchParams]);
  return <div className="LoginCallback"></div>;
}

export default observer(LoginCallback);
