import axios from "axios";
import {
    CognitoUser,
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
    CognitoUserSession,
} from "amazon-cognito-identity-js";
import { authUrl, cognitoClientId, loginRedirectUri } from "../project/GSC";
import { userPool } from "./cognito";


export async function loginCallback(code) {
    const url = `${authUrl}/token`;
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", code);
    params.append("client_id", cognitoClientId);
    params.append("redirect_uri", loginRedirectUri);
    const config2 = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
    const data = (await axios.post(url, params, config2)).data;
    delete data.expires_in;
    delete data.token_type;
    const newKeys = {
        id_token: "IdToken",
        refresh_token: "RefreshToken",
        access_token: "AccessToken",
    }
    const newData = renameKeys(data, newKeys)
    newData["IdToken"] = new CognitoIdToken({ IdToken: newData["IdToken"] })
    newData["RefreshToken"] = new CognitoRefreshToken({ RefreshToken: newData["RefreshToken"] })
    newData["AccessToken"] = new CognitoAccessToken({ AccessToken: newData["AccessToken"] })
    const  cognitoUser = new CognitoUser({
        Username: newData["IdToken"].payload["cognito:username"],
        Pool: userPool
    })
    // create user in prod manually
    // await createUser(newData["IdToken"].payload["cognito:username"], "FREE", null)
    console.log("Creating user");
    const session = new CognitoUserSession(newData)
    cognitoUser.setSignInUserSession(session)
    return session;
}

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}
