import axios from "axios";
import { userSession } from "../components/security/cognito";
import { AuthStore } from "../store/authStore";

export const URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.seohero.ai/seo-hero-service"
    : "http://localhost:5000/seo-hero-service";

const headers = () => {
  return {
    Authorization: `Bearer ${AuthStore.session.getIdToken().getJwtToken()}`,
  };
};

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    let originalConfig = err.config;
    if (err.response.status === 401) {
      if (AuthStore.authenticated && AuthStore.session != null) {
        try {
          originalConfig._retry = true;
          const session = await userSession(AuthStore.session);
          AuthStore.interceptorSetSession(session);
          originalConfig.headers = headers();
          originalConfig.headers["content-type"] = "application/json";
          return axios(originalConfig);
        } catch (e) {
          return Promise.reject(e);
        }
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export async function getUser() {
  return (await axios.get(`${URL}/user`, { headers: headers() })).data;
}

export async function getUsers() {
  return (await axios.get(`${URL}/users`, { headers: headers() })).data;
}

export async function getUserById(userId) {
  return (await axios.get(`${URL}/user/${userId}`, { headers: headers() }))
    .data;
}

export async function getProjects() {
  return (await axios.get(`${URL}/project`, { headers: headers() })).data;
}

export async function getProject(id) {
  return (await axios.get(`${URL}/project/${id}`, { headers: headers() })).data;
}

export async function deleteProject(projectId) {
  return (
    await axios.delete(`${URL}/project/${projectId}`, { headers: headers() })
  ).data;
}

export async function createProject(name, description) {
  const data = {
    name,
    description,
    brand_name: name,
    beginning_word: "Discover",
    ending_cta: "Enjoy Free Shipping & Returns",
  };
  return (await axios.post(`${URL}/project`, data, { headers: headers() }))
    .data;
}

export async function createDefaultProject() {
  const data = {
    beginning_word: "Discover",
    ending_cta: "Enjoy Free Shipping & Returns",
  };
  return (
    await axios.post(`${URL}/project/default`, data, { headers: headers() })
  ).data;
}

export async function getUrls(projectId) {
  return (
    await axios.get(`${URL}/project/${projectId}/url`, { headers: headers() })
  ).data;
}

export async function getUrl(urlId) {
  return (await axios.get(`${URL}/url/${urlId}`, { headers: headers() })).data;
}

export async function deleteUrl(urlId) {
  return (await axios.delete(`${URL}/url/${urlId}`, { headers: headers() }))
    .data;
}

export async function createUrl(projectId, url, keywords) {
  const data = {
    url,
    keywords,
  };

  return (
    await axios.post(`${URL}/project/${projectId}/url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function publishUrls(projectId, urlIds) {
  const data = {
    url_ids: urlIds,
    action: "publish",
  };

  return (
    await axios.post(`${URL}/project/${projectId}/bulk-url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function unPublishUrls(projectId, urlIds) {
  const data = {
    url_ids: urlIds,
    action: "unpublish",
  };

  return (
    await axios.post(`${URL}/project/${projectId}/bulk-url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function unpublishUrls(projectId, urlIds) {
  const data = {
    url_ids: urlIds,
    action: "unpublish",
  };

  return (
    await axios.post(`${URL}/project/${projectId}/bulk-url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function deleteUrls(projectId, urlIds) {
  const data = {
    url_ids: urlIds,
    action: "delete",
  };

  return (
    await axios.post(`${URL}/project/${projectId}/bulk-url`, data, {
      headers: headers(),
    })
  ).data;
}

export async function updateUrl(
  id,
  url,
  keywords,
  copy_keywords,
  title,
  meta,
  seo_copy,
  published
) {
  const data = {
    url,
    keywords,
    copy_keywords,
    title,
    meta,
    seo_copy,
    published,
  };
  return (
    await axios.put(`${URL}/url/${id}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function generateUrlGpt(projectId, urlId, text_type) {
  return (
    await axios.post(
      `${URL}/project/${projectId}/url/${urlId}/${text_type}`,
      {},
      {
        headers: headers(),
      }
    )
  ).data;
}

export async function inviteUser(email, firstname, lastname, role) {
  const data = {
    firstname,
    email,
    username: email,
    lastname,
    role,
  };
  return (await axios.post(`${URL}/user`, data, { headers: headers() })).data;
}

export async function updateUser(
  email,
  firstname,
  lastname,
  role,
  resend = false
) {
  const data = {
    firstname,
    email,
    username: email,
    lastname,
    role,
  };
  const params = {};
  if (resend) {
    params["resend"] = true;
  }
  return (
    await axios.put(`${URL}/user`, data, { headers: headers(), params: params })
  ).data;
}

export async function saveOauthCode(code, redirectUrl, projectId, clientType) {
  return (
    await axios.post(
      `${URL}/project/${projectId}/oauth`,
      {
        code: code,
        redirect_url: redirectUrl,
        project_id: projectId,
        client_type: clientType,
      },
      { headers: headers(), params: { code: code } }
    )
  ).data;
}

export async function deleteProjectOauth(projectId, projectOauthId) {
  return (
    await axios.delete(`${URL}/project/${projectId}/oauth/${projectOauthId}`, {
      headers: headers(),
    })
  ).data;
}

export async function updateProjectOauth(
  projectId,
  projectOauthId,
  propertyId
) {
  return (
    await axios.put(
      `${URL}/project/${projectId}/oauth/${projectOauthId}`,
      { property_id: propertyId },
      { headers: headers() }
    )
  ).data;
}

export async function getProjectOauth(projectId, clientType, refresh = false) {
  return (
    await axios.get(`${URL}/project/${projectId}/oauth`, {
      headers: headers(),
      params: { refresh: refresh, clientType: clientType },
    })
  ).data;
}

export async function updateProject(
  projectId,
  name,
  description,
  ownerId,
  beginningWord,
  brandName,
  endingCTA,
  autoPopulate,
  autoPublish,
  sitemaps
) {
  const data = {
    owner_id: ownerId,
    name,
    description,
    beginning_word: beginningWord,
    brand_name: brandName,
    ending_cta: endingCTA,
    auto_populate: autoPopulate,
    auto_publish: autoPublish,
    sitemaps,
  };
  return (
    await axios.put(`${URL}/project/${projectId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function setProjectUsers(projectId, userIds) {
  const data = { user_ids: userIds };
  return (
    await axios.put(`${URL}/project/${projectId}/user`, data, {
      headers: headers(),
    })
  ).data;
}

export async function getPrompts() {
  return (await axios.get(`${URL}/global`, { headers: headers() })).data;
}

export async function updatePrompt(promptId, text_type, prompt, configs) {
  const data = {
    text_type,
    prompt,
    configs,
  };

  return (
    await axios.put(`${URL}/global/${promptId}`, data, {
      headers: headers(),
    })
  ).data;
}

export async function loadPlans() {
  const plans = (await axios.get(`${URL}/plan`, { headers: headers() })).data;
  return plans;
}

export async function deleteSubscription() {
  return (await axios.delete(`${URL}/subscription`, { headers: headers() }))
    .data;
}

export async function loadRoles() {
  const me = (await axios.get(`${URL}/me`, { headers: headers() })).data
    .organization;
  const roles = me["subscription"];
  return { me, roles };
}

export async function setupIntent() {
  const intent = (
    await axios.post(`${URL}/payment`, {}, { headers: headers() })
  ).data;
  return intent;
}

export async function associateCard(paymentMethodId) {
  const intent = (
    await axios.post(
      `${URL}/payment/attach/${paymentMethodId}`,
      {},
      { headers: headers() }
    )
  ).data;
  return intent;
}

export async function updateBillingInfo(payload) {
  return (await axios.put(`${URL}/billing`, payload, { headers: headers() }))
    .data;
}

export async function getInvoices() {
  const invocies = (await axios.get(`${URL}/payment`, { headers: headers() }))
    .data;
  return invocies;
}

export async function subscribe(plan) {
  const intent = (
    await axios.post(`${URL}/subscription/${plan}`, {}, { headers: headers() })
  ).data;
  return intent;
}

export async function deleteUser(userId) {
  return (await axios.delete(`${URL}/user/${userId}`, { headers: headers() }))
    .data;
}

export async function getUploadURLs(projectId) {
  return (await axios.post(`${URL}/project/${projectId}/url/upload`, {})).data;
}

export async function importProjectUrls(projectId, key) {
  return (
    await axios.post(
      `${URL}/project/${projectId}/url/import`,
      {
        key: key,
      },
      { headers: headers() }
    )
  ).data;
}

export async function setupShopify(projectId, shop) {
  const intent = await (axios.post(`${URL}/project/${projectId}/shopify`, {
    shop: shop
  }, { headers: headers() })
  ).data;
  return intent;
}