import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Table, Skeleton, List } from "antd";
import { Spin, notification } from "antd";
import { LoadingOutlined, LeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { createUrl, getProject, getProjectOauth, updateProject } from "../../utils/api";
import { useParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

import "./AddUrl.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
const { TextArea } = Input;

function AddUrl({ navStore, authStore }) {
  const { id } = useParams();

  const [inputType, setInputType] = useState("url");
  const [projectOauth, setProjectOauth] = useState(null);


  const [gscTableData, setGscTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [urlSubmitting, setUrlSubmitting] = useState(false);


  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [urls, setUrls] = useState("");
  const [searchText, setSearchText] = useState("");
  const [startRow, setStartRow] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sitemaps, setSiteMaps] = useState([]);
  const [project, setProject] = useState({});


  const navigate = useNavigate();
  navStore.updateSelectedKey("4");

  const fetchData = async () => {
    try {
      const projectOauth = await getProjectOauth(id, "google", true);
      setProjectOauth(projectOauth);
      fetchDataForProperty(projectOauth[0].property_id, projectOauth[0].access_token);
    } catch (e) {
      setProjectOauth(null);
    }

    const project = await getProject(id);
    setProject(project[0]);
    if (project[0].sitemaps !== null) {
      setSiteMaps(project[0].sitemaps);
    } else {
      setSiteMaps([]);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async () => {
    setError("");
    setSubmitting(true);
    try {
      await createUrl(id, [...urls.split("\n"), ...sitemaps]);
      notification.open({
        message: "Url Added",
      });
      navigate(`/project/${id}`);
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const inputTypeChanged = (e) => {
    console.log(e.target.value);
    setInputType(e.target.value);
  };

  const rowSelection = {
    hideSelectAll: false,
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const fetchDataForProperty = async (propertyName, accessToken, loadMore = false, rowLimit = 100) => {
    if (!loadMore) {
      setTableLoading(true);
    }
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const sDate = new Date();
    sDate.setDate(sDate.getDate() - 28);
    const data = {
      startDate: sDate.toISOString().substring(0, 10),
      endDate: `${year}-${month}-${day}`,
      dimensions: ["PAGE"],
      searchType: "WEB",
      'startRow': startRow,
      'rowLimit': rowLimit
    };

    const pageResponse = await fetch(
      `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(
        propertyName
      )}/searchAnalytics/query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (pageResponse.ok) {
      const response = await pageResponse.json();
      if ("rows" in response) {
        const newData = [...gscTableData, ...response['rows']]
        if (newData.length <= 1000) {
          setGscTableData(newData)
          let startRow_ = startRow + rowLimit
          setStartRow(startRow_)
          setShowLoadMore(true)
        } else {
          setShowLoadMore(false);
          notification.open({
            message: "All the data from GSC has been fetched.",
          });
        }
      } else {
        setShowLoadMore(false);
        notification.open({
          message: "All the data from GSC has been fetched.",
        });
      }
    }

    setTableLoading(false);
  };

  const handleLoadMore = async () => {
    setLoading(true);
    await fetchDataForProperty(projectOauth[0].property_id, projectOauth[0].access_token, true);
    setLoading(false);
  }

  const addUrls = async () => {
    setUrlSubmitting(true);
    try {
      setError("");
      await createUrl(id, selectedRowKeys);
      setSelectedRowKeys([]);
      notification.open({
        message: "Url Added",
      });
      navigate(`/project/${id}`);
    } catch (e) {
      setUrlSubmitting(false);
      setError(e.response.data.message);
    } finally {
      setUrlSubmitting(false);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const getFilteredData = () => {
    if (searchText === '') {
      return gscTableData;
    }

    return gscTableData.filter((item) =>
      item.keys[0].toLowerCase().includes(searchText.toLowerCase())
    );
  };


  const gscColumn = [
    {
      title: "Url",
      key: "keys",
      render: (_, record) => {
        return <div>{record.keys[0]}</div>;
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      width: "100px",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      width: "115px",
    },
  ];


  const removeSiteMap = async (sitemap) => {
    const index = sitemaps.indexOf(sitemap);
    sitemaps.splice(index, 1)
    setSiteMaps((prev) => prev.filter((sitemap_) => sitemap_ !== sitemap))

    await updateProject(
      project.id,
      project.name,
      project.description,
      project.owner_id,
      project.beginning_word,
      project.brand_name,
      project.endingCTA,
      project.auto_populate,
      project.auto_publish,
      sitemaps,
    );
  }

  return (
    <div className="AddUrl">
      <div className="AddUrl__wrapper">
        <div className="AddUrl__container">
          <Button
            className="ProjectPermission__container_back"
            type="text"
            onClick={() => navigate(-1)}
          >
            <LeftOutlined /> Back
          </Button>
          <h2>Add url</h2>

          {projectOauth &&
            projectOauth.length > 0 &&
            projectOauth[0].connected && (
              <Radio.Group
                p
                value={inputType}
                style={{ marginBottom: "20px" }}
                onChange={inputTypeChanged}
              >
                <Radio.Button value="url" selected>
                  Sitemap URL or list of URLs
                </Radio.Button>
                <Radio.Button value="gsc">Google Search Console</Radio.Button>
              </Radio.Group>
            )}

          {inputType === "url" && (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
              layout="vertical"
              onFinish={onFinish}
            >
              {sitemaps && sitemaps.length > 0 &&
                <div
                  id="scrollableDiv"
                  style={{
                    maxHeight: "200px",
                    overflow: 'auto',
                    padding: '0 16px',
                    marginTop: '20px',
                    marginBottom: "20px"
                  }}
                >
                  <h3>Sitemaps</h3>
                  <InfiniteScroll
                    dataLength={sitemaps.length}
                    loader={
                      <Skeleton
                        paragraph={{
                          rows: 1,
                        }}
                        active
                      />
                    }
                    scrollableTarget="scrollableDiv"
                  >
                    <List
                      dataSource={sitemaps}
                      style={{
                        padding: "1px 0px"
                      }}
                      renderItem={(item) => (
                        <List.Item key={item} style={{
                          padding: "3px 0px"
                        }}>
                          <p>{item}</p>
                          <Button
                            type="outline"
                            onClick={() => removeSiteMap(item)}
                            style={{
                              fontSize: "12px",
                              padding: "0px 8px"
                            }}
                          >
                            <DeleteOutlined />
                          </Button>
                        </List.Item>
                      )}
                    />
                  </InfiniteScroll>
                </div>
              }
              <Form.Item
                className="AddUrl__item"
                label="URLs"
                name="url"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter a sitemap url or one or more url separated by a new line",
                  },
                ]}
              >
                <TextArea
                  rows={8}
                  onChange={(e) => setUrls(e.target.value)}
                  placeholder="Sitemap URL or page URLs separated by a new line"
                />
              </Form.Item>

              {error && <div className="error">{error}</div>}
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  {submitting && <Spin indicator={antIcon} />}
                  {!submitting && <span> Add URLs</span>}
                </Button>
              </Form.Item>
            </Form>
          )}

          {inputType === "gsc" && (
            <>

              <Input.Search
                placeholder="Search by url..."
                onChange={(e) => handleSearch(e.target.value)}
                value={searchText}
                style={{ marginBottom: 16 }}
              />
              <Table
                rowSelection={rowSelection}
                dataSource={getFilteredData()}
                columns={gscColumn}
                pagination={false}
                rowKey={(record) => record.keys[0]}
                style={{ tableLayout: "fixed" }}
                size="small"
                loading={tableLoading}
              />
              {showLoadMore &&
                <Button
                  onClick={handleLoadMore}
                  loading={loading}
                  style={{
                    float: "right",
                    margin: "12px 0px",
                  }}
                >
                  Load More
                </Button>
              }

              {error && <div className="error">{error}</div>}

              <Button
                type="secondary"
                onClick={addUrls}
                block
                disabled={selectedRowKeys.length > 0 ? false : true}
              >
                {urlSubmitting && <Spin indicator={antIcon} />}
                {!urlSubmitting && <span> Add URLs</span>}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(AddUrl);
